import React from "react";
import User from "../../../images/SandboxIcons/user.svg";
import Check from "../../../images/SandboxIcons/check.svg";
import Briefcase from "../../../images/SandboxIcons/briefcase.svg";
import Target from "../../../images/SandboxIcons/Target.svg";

export const Facts = () => {
  return (
    <section className="wrapper bg-light">
      <div className="container py-10 py-md-12">
        <div className="row">
          <div className="col-xl-10 mx-auto">
            <div className="row align-items-center counter-wrapper gy-6 text-center">
              <div className="col-6 col-md-3">
                <img
                  src={Check}
                  className="ms-auto me-auto icon-svg icon-svg-lg text-primary mb-3"
                  alt=""
                />
                <h3 className="counter">68000 Hrs+</h3>
                <p>RTK Hours</p>
              </div>
              <div className="col-6 col-md-3">
                <img
                  src={Briefcase}
                  className="ms-auto me-auto icon-svg icon-svg-lg text-primary mb-3"
                  alt=""
                />
                <h3 className="counter">220+</h3>
                <p>Happy Customers</p>
              </div>
              <div className="col-6 col-md-3">
                <img
                  src={Target}
                  className="ms-auto me-auto icon-svg icon-svg-lg text-primary mb-3"
                  alt=""
                />
                <h3 className="counter">15</h3>
                <p>CORS Stations</p>
              </div>
              <div className="col-6 col-md-3">
                <img
                  src={User}
                  className="ms-auto me-auto icon-svg icon-svg-lg text-primary mb-3"
                  alt=""
                />
                <h3 className="counter">35</h3>
                <p>Expert Employees</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
